import { graphql, Link } from "gatsby";
import React, { useState } from "react";

import Activities from "../components/Activities/Activities";
import Layout from "../components/common/layout/layout";
// import { Contact } from "../components/Forms/Contact/Contact";
// import withForm from "../components/Forms/withForm";
import Hero from "../components/Hero/Hero";
import Map from "../components/Map/Map";
// import ModalForm from "../components/Modal/ModalForm";
import ModalVideo from "../components/Modal/ModalVideo";
import SEO from "../components/SEO/SEO";
import PcisBanners from "../PCIS/components/PCIsBanner/PcisBanners";

const PageInsertaXXI = ({ data }) => {
  // const ContactWithForm = withForm(Contact);
  const [has_modal, setHasModal] = useState(false);
  const [from, setFrom] = useState("Actividades complementarias");

  return (
    <>
      {has_modal && <ModalVideo title={"Video Inserta XXI"} is_active={setHasModal} code={from} />}
      <SEO
        title={"Proyectos Erasmus+"}
        canonical={"inserta-xxi"}
        description={""}
        robots={"index, follow"}
      />
      <Layout>
        <Hero title={"Proyectos Erasmus+"} />
        <main>
          <section className={"container"}>
            <div style={{ maxWidth: "800px" }}>
              <p>
                Comenzamos nuestra andadura en Erasmus+ en el curso 2020/2021 con un proyecto K210 de
                intercambio de buenas prácticas cuyo acrónimo es INSERTA XXI.  </p>
                Fuimos los coordinadores de este proyecto y nuestros centros socios se localizan en Mantua
                (Italia), Satu Mare (Rumanía) y Zagreb (Croacia). Al igual que nosotros, ellos también trabajan
                con alumnado con diversidad funcional y lo forman para el mundo del trabajo.
              <p>
                Aunque este proyecto ya ha finalizado, desde el curso 2021/2022, nuestro centro se encuentra
                adherido a un consorcio de movilidades del CIFPA (Centro de Innovación para la Formación
                Profesional de Aragón). </p> <p>
                Gracias a este consorcio, alumnos y alumnas han tenido y van a tener la oportunidad de
                realizar una semana de prácticas en el extranjero, como parte de su formación en centros de
                trabajo. Y también, miembros del profesorado, la oportunidad de conocer nuevos centros y abrir
                horizontes para nuevos viajes de alumnado.              </p>
              <p>
                En los siguientes enlaces pueden ver las diferentes movilidades y formaciones que se han llevado a cabo.
              </p>
              <div className={"link"}>
                <Link to={"/training-days/"} className={"hey"}>
                  {" "}
                  SPANISH ONLINE TRAINING DAY{" "}
                </Link>
              </div>
            </div>
          </section>
          <section>
            <div className={"container"}>
              <h2>Más actividades Inserta XXI</h2>
              <Activities activities={data.allSanityInsertXxi.nodes} select={setHasModal} from={setFrom} video={true} />
            </div>
          </section>
          <PcisBanners />
          <Map>
            <h2> ¿Necesitas ponerte en contacto con el departamento PCI IES Ramón y Cajal?</h2>
            <p>
              Si tienes más dudas, envía un correo electrónico a la siguiente dirección{" "}
              <a href={"mailto:pci.ryc.zgz@gmail.com"} target={"_blank"} rel={"noopener noreferrer"}>
                {" "}
                pci.ryc.zgz@gmail.com{" "}
              </a>{" "}
              o rellena el siguiente formulario de información
            </p>
            {/*<ContactWithForm />*/}
          </Map>
        </main>
      </Layout>
    </>
  );
};

export default PageInsertaXXI;

export const query = graphql`
  query Insert {
    allSanityInsertXxi {
      nodes {
        name
        _rawDescription
        photo {
          asset {
            gatsbyImageData
          }
        }
        video
      }
    }
  }
`;
